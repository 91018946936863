export default [
    {
        path: '/account',
        redirect: '/account/profile',
        component: () => import('@/views/layouts/default'),
        children: [
            {
                path: '/account/profile',
                meta: {
                    authorize: true
                },
                component: () => import('@/views/account/profile')
            },
            {
                path: '/account/change-password',
                meta: {
                    authorize: true
                },
                component: () => import('@/views/account/changePassword')
            }
        ]
    }
];
