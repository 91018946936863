import common from './common';
import error from './error';
import validation from './validation';
import models from './models';
import menu from './menu';
import views from './views';

export default {
    ...common,
    ...error,
    ...validation,
    ...models,
    ...menu,
    ...views,
    '$vuetify': {
        'badge': '徽章',
        'close': '关',
        'dataIterator': {
            'noResultsText': '没有找到匹配的记录',
            'loadingText': '正在载入项目...'
        },
        'dataTable': {
            'itemsPerPageText': '每页行数：',
            'ariaLabel': {
                'sortDescending': '降序排列。',
                'sortAscending': '升序排列。',
                'sortNone': '未排序。',
                'activateNone': '激活以删除排序。',
                'activateDescending': '激活以降序排列。',
                'activateAscending': '激活以升序排序。'
            },
            'sortBy': '排序方式'
        },
        'dataFooter': {
            'itemsPerPageText': '每页项目：',
            'itemsPerPageAll': '所有',
            'nextPage': '下一页',
            'prevPage': '上一页',
            'firstPage': '第一页',
            'lastPage': '最后一页',
            'pageText': '{2}中的{0}-{1}'
        },
        'datePicker': {
            'itemsSelected': '已选择{0}',
            'nextMonthAriaLabel': '下个月',
            'nextYearAriaLabel': '明年',
            'prevMonthAriaLabel': '前一个月',
            'prevYearAriaLabel': '前一年'
        },
        'noDataText': '没有可用数据',
        'carousel': {
            'prev': '以前的视觉',
            'next': '下一个视觉',
            'ariaLabel': {
                'delimiter': '{1}的轮播幻灯片{0}'
            }
        },
        'calendar': {
            'moreEvents': '还有{0}个'
        },
        'fileInput': {
            'counter': '{0}个文件',
            'counterSize': '{0}个文件（共{1}个）'
        },
        'timePicker': {
            'am': 'AM',
            'pm': 'PM'
        },
        'pagination': {
            'ariaLabel': {
                'wrapper': '上午',
                'next': '下午',
                'previous': '分页导航',
                'page': '下一页',
                'currentPage': '上一页'
            }
        }
    }
};
