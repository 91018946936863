export default {
    views: {
        layouts: {
            passport: {
                slogan: 'Welcome! Let\'s build amazing things together.'
            }
        },
        passport: {
            login: {
                welcome: 'Welcome',
                loginWithPassword: 'Sign in',
                loginWithMobile: 'Sign in with phone',
                loginWithEmail: 'Sign in',
                subtitle: 'Sign in to your account',
                noaccount: 'Don\'t have an account?',
                create: 'Create one here',
                button: 'Sign In',
                orsign: 'Or sign in with',
                forgot: 'Forgot password?'
            },
            loginWithPassword: {
                username: 'Name',
                /*eslint-disable */
                username_mobile: 'Phone, or name',
                username_email: 'Email or name',
                username_mobile_email: 'Email, phone, or name',
                /*eslint-enabled */
                password: 'Password'
            },
            loginWithMobile: {
                country: 'Country',
                mobilePhone: 'Phone',
                captcha: 'Captcha',
                sendCaptcha: 'Send code',
                button: 'Sign In'
            },
            loginWithEmail: {
                email: 'Email',
                captcha: 'Captcha',
                sendCaptcha: 'Send code',
                button: 'Sign In'
            },
            logout: {
                title: 'Log out',
                alert: 'Whether to log out?',
                yes: 'Yes',
                no: 'No'
            },
            loggedOut: {
                title: 'You are now logged out.',
                redirect: 'Click <a href="{url}">here</a> to return to the <span>{clientName}</span> application.'
            },
            register: {
                title: 'Register',
                subtitle: 'Start a new journey',
                country: 'Country',
                mobilePhone: 'Phone',
                email: 'email',
                captcha: 'Captcha',
                sendCaptcha: 'Send code',
                nameIdentifier: 'Login',
                name: 'Display name',
                password: 'Password',
                next: 'Next',
                prev: 'Previous',
                button: 'Submit',
                hasAccount: 'Already have an account?',
                login: 'Log in',
                agree: 'By signing up, you agree to the',
                nameIdentifierHint: '4-20 ignore case characters. (a-z, 0-9, -, _)',
                nameHint: '2-20 ignore case characters',
                passwordHint: '6-20 case sensitive characters',
                mobileLoginAccountIs: 'Your mobile login account is:',
                emailLoginAccountIs: 'Your email login account is:'
            },
            provision: {
                title: 'Provision',
                associateWithCurrent: 'Associated with current account ({name})',
                associateWithOther: 'Associated with existing account',
                createNewAccount: 'Create a new account'
            }
        },
        account: {
            profile: {
                title: 'Account profile',
                nameIdentifierHint: 'It can be modified twice a year',
                nameHint: 'It can be modified twice a year',
                editAvatar: 'Edit avatar'
            },
            // 修改手机号码
            editMobilePhoneDialog: {
                title: 'Change phone number',
                country: 'Country',
                mobilePhone: 'Phone',
                captcha: 'Captcha',
                sendCaptcha: 'Send code'
            },
            // 修改邮箱
            editEmailDialog: {
                title: 'Change email',
                email: 'Email',
                captcha: 'Captcha',
                sendCaptcha: 'Send code'
            },
            // 修改密码
            changePassword: {
                title: 'Change password',
                oldPassword: 'Old password',
                newPassword: 'New password',
                confirmPassword: 'Confirm password',
                success: 'Password changed'
            }
        },
        consent: {
            alertHtml: '<b>{clientName}</b> is requesting your permission',
            yes: 'Yes, Allow',
            no: 'No, Do not allow',
            changeAccount: 'Use other account',
            invalidConsentRequest: 'Invalid request'
        },
        grants: {
            title: 'Grants',
            revoke: 'Revoke access',
            noGrants: 'You have not given access to any applications',
            created: 'Created',
            expires: 'Expires',
            identityGrants: 'Identity Grants',
            apiGrants: 'API Grants'
        },
        error: {
            index: {
                title: 'Some error occurred'
            },
            400: {
                title: 'OOPS! Something went wrong here',
                content: 'Our experts are working to fix the issue.',
                button: 'Send me Back'
            },
            404: {
                title: 'How did you get here?',
                content: 'Sorry we can\'t seem to find the page you\'re looking for.',
                button: 'Send me Back'
            }
        }
    }
};
