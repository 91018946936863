import Vue from 'vue';
import App from './App.vue';

/**
 * 存储,路由,多语言
 */
import store from './store';
import router from './router';
import i18n from './i18n';

/**
 * 导入插件
 */
import plugins from './plugins';
Vue.use(plugins);

// 导入vuetify
import vuetify from './plugins/vuetify';

// 导入样式
import './assets/scss/theme.scss';

/**
 * 构建应用
 */
// 是否输出信息
Vue.config.productionTip = false;

new Vue({
    i18n,
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app');
