import _ from 'lodash';
import axios from 'axios';
import store from '@/store';
import i18n from '@/i18n';

// 创建一个 axios 实例
const service = axios.create({
    baseURL: '/api/',

    // 请求超时时间
    timeout: 10000,

    // 允许携带cookie
    withCredentials: true
});

service.interceptors.request.use(
    config => {
        // 根据当前会话的语言来配置ajax请求的HEADER, 在API服务端会根据参数切换语言
        var currentCulture = store.getters['session/currentCulture'];
        if (currentCulture) {
            config.headers['Content-Language'] = currentCulture;
        }

        return config;
    },
    error => {
        Promise.reject(error);
    }
);

service.interceptors.response.use(
    response => {
        var contentType = response.headers['content-type'];

        if (_.startsWith(contentType, 'application/json')) {
            const dataAxios = response.data;
            if (dataAxios && dataAxios.error && dataAxios.errorMessages) {
                var message;
                for (var p in dataAxios.errorMessages) {
                    if (!p) {
                        message = dataAxios.errorMessages[p];
                    }
                }

                if (!message) {
                    var keys = _.keys(dataAxios.errorMessages);
                    if (keys.length === 1) {
                        message = dataAxios.errorMessages[keys[0]];
                    }
                }

                // 注意，这里的message会出现undefined,设置到Error的话 err.message === '', 开发人员可以根据需求显示错误信息
                var err = new Error(message || i18n.tc('Common.error'));
                err.error = dataAxios.error;
                err.errorMessages = dataAxios.errorMessages;
                throw err;
            } else {
                return dataAxios;
            }
        } else {
            return response;
        }
    },
    async error => {
        // TODO: 此处需要翻译
        if (error && error.response) {
            switch (error.response.status) {
            case 400:
            case 401:
            case 403:
            case 404:
            case 408:
            case 500:
            case 501:
            case 502:
            case 503:
            case 504:
            case 505:
                error.message = i18n.tc(`error.${error.response.status}`, { url: error.response.config.url });
                break;
            default: break;
            }
        }
        return Promise.reject(error);
    }
);

export default service;
