// import maps from './maps'
// import time from './time'
// import icons from './icons'
import theme from './theme';
// import toolbar from './toolbar'
// import locales from './locales'
// import analytics from './analytics'
// import currencies from './currencies'
import navigation from './navigation';

export default {
    /**
     * 产品信息
     */
    product: {
        name: '科迩',
        version: '1.0.0'
    },

    // // google maps
    // maps,

    // // time configs
    // time,

    // // icon libraries
    // icons,

    // 样式配置
    theme,

    // // toolbar configs
    // toolbar,

    // // locales configs
    // locales,

    // // analytics configs
    // analytics,

    // // currencies configs
    // currencies,

    // 导航菜单
    navigation
};
