import request from '@/utils/request';

export function getGrants () {
    return request({
        url: '/grants/getGrants',
        method: 'post',
        data: {}
    });
}

export function revoke ({ clientId }) {
    return request({
        url: '/grants/revoke',
        method: 'post',
        data: {
            clientId
        }
    });
}
