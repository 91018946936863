import request from '@/utils/request';

export function getSupportCultures () {
    return request({
        url: '/app/getSupportCultures',
        method: 'post',
        data: {}
    });
};

export function getSiteProfile ({ culture }) {
    return request({
        url: '/app/getSiteProfile',
        method: 'post',
        data: {
            culture
        }
    });
};

export function getErrorMessage ({ errorId }) {
    return request({
        url: '/app/getErrorMessage',
        method: 'post',
        data: {
            errorId
        }
    });
};
