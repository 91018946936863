/**
 * Vuetify
 */

import Vue from 'vue';
import i18n from '@/i18n';
import config from '../configs';

// // For full framework
// import Vuetify from 'vuetify/lib/framework';

// For a-la-carte components - https://vuetifyjs.com/en/customization/a-la-carte/
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib';
import VuetifyToast from '@/components/Toast';

// 引入指示器
import * as directives from 'vuetify/lib/directives';

Vue.use(Vuetify, {
    directives,
    components: {
        VSnackbar,
        VBtn,
        VIcon
    }
});

// 引入图标样式, 这里使用的是mdi图标(material design icons)
// (Ensure you are using css-loader)
import '@mdi/font/css/materialdesignicons.css';

/**
 * Vuetify组件库
 */
var veutifyObj = new Vuetify({
    rtl: config.theme.isRTL,
    theme: {
        dark: config.theme.globalTheme === 'dark',
        options: {
            customProperties: true
        },
        themes: {
            dark: config.theme.dark,
            light: config.theme.light
        }
    },
    lang: {
        // locales: { zhHans },
        current: 'en',
        t: (key, ...params) => i18n.t(key, params)
    },
    icons: {
        iconfont: 'mdi'
    }
});
Vue.use(VuetifyToast, { $vuetify: veutifyObj.framework });
export default veutifyObj;
