import request from '@/utils/request';

export function getConsentViewModel ({ returnUrl }) {
    return request({
        url: '/consent/getConsentViewModel',
        method: 'post',
        data: {
            returnUrl
        }
    });
}

export function processConsent (model) {
    return request({
        url: '/consent/processConsent',
        method: 'post',
        data: model
    });
}
