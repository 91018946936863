export default {
    common: {
        // add: '加',
        // cancel: '取消',
        // description: '描述',
        // delete: '删除',
        // title: '标题',
        save: '保存',
        submit: '提交',
        close: '关闭',
        ok: '确定',
        cancel: '取消',
        reset: '重置',
        // faq: '常问问题',
        // contact: '联系我们',
        error: '出现一些错误',
        tos: '服务条款',
        policy: '隐私政策',
        required: '必要项',
        optional: '可选项',
        unset: '未设置'
    }
};
