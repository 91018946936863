import i18n from '@/i18n';

export default {
    /**
     * 用户取消了操作
     */
    userCancelled () {
        var message = i18n.tc('error.1');
        var err = new Error(message);
        err.error = 1;
        return err;
    },

    /**
     * 记录找不到
     */
    noContent () {
        var message = i18n.tc('error.204');
        var err = new Error(message);
        err.error = 204;
        return err;
    }
}
