import _ from 'lodash';

export default {
    getQueryString ($route, name) {
        var temp = name.toLowerCase();
        return _.find($route.query, (val, key) => key.toLowerCase(key) === temp);
    },

    generateUrl (url, querys) {
        var queryStrings = [];
        for (var k in querys) {
            var v = querys[k];
            if (v) {
                queryStrings.push(`${encodeURIComponent(k)}=${encodeURIComponent(querys[k])}`);
            }
        }
        if (queryStrings.length > 0) {
            return `${url}?${queryStrings.join('&')}`;
        } else {
            return url;
        }
    }
};
