import _ from 'lodash';
import utils from '@/utils';

export default {
    methods: {
        toastError: function (ex) {
            if (ex.error > 100) {
                this.$toast.error(ex.message);
            }
        },
        setDocumentTitle (title) {
            document.title = title;
        },
        findComponent (predicate) {
            var stack = [this];

            while (stack.length > 0) {
                var item = stack.pop();
                if (predicate(item)) {
                    return item;
                }

                if (item.$children && item.$children.length > 0) {
                    _.eachRight(item.$children, (subItem) => {
                        stack.push(subItem);
                    });
                }
            }
            return null;
        },
        generateUrl (url, qs) {
            return utils.route.generateUrl(url, qs);
        },
        setTheme (theme) {
            if (!theme) return;

            var vuetifyThemes = this.$vuetify.theme.themes;

            var prop;
            if (theme.light) {
                for (prop in theme.light) {
                    this.$set(vuetifyThemes.light, prop, theme.light[prop]);
                }
            }
            if (theme.dark) {
                for (prop in theme.dark) {
                    this.$set(vuetifyThemes.dark, prop, theme.dark[prop]);
                }
            }
        }
    }
};
