import request from '@/utils/request';

export function getAccountInfo () {
    return request({
        url: '/account/getAccountInfo',
        method: 'post',
        data: {}
    });
}

export function updatePersonalNameIdentifier (model) {
    return request({
        url: '/account/updatePersonalNameIdentifier',
        method: 'post',
        data: model
    });
}

export function updatePersonalName (model) {
    return request({
        url: '/account/updatePersonalName',
        method: 'post',
        data: model
    });
}

export function sendUpdatePersonalMobilePhoneCaptcha (model) {
    return request({
        url: '/account/sendUpdatePersonalMobilePhoneCaptcha',
        method: 'post',
        data: model
    });
}

export function updatePersonalMobilePhone (model) {
    return request({
        url: '/account/updatePersonalMobilePhone',
        method: 'post',
        data: model
    });
}

export function sendUpdatePersonalEmailCaptcha (model) {
    return request({
        url: '/account/sendUpdatePersonalEmailCaptcha',
        method: 'post',
        data: model
    });
}

export function updatePersonalEmail (model) {
    return request({
        url: '/account/updatePersonalEmail',
        method: 'post',
        data: model
    });
}

export function updatePersonalAvatar (model) {
    return request({
        url: '/account/updatePersonalAvatar',
        method: 'post',
        data: model
    });
}

export function updatePersonalAccountInfo (model) {
    return request({
        url: '/account/updatePersonalAccountInfo',
        method: 'post',
        data: model
    });
}

export function updatePersonalPassword (model) {
    return request({
        url: '/account/updatePersonalPassword',
        method: 'post',
        data: model
    });
}
