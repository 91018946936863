export default [
    {
        key: 'menu.account',
        items: [
            {
                icon: 'mdi-account',
                key: 'menu.profile',
                link: '/account/profile'
            },
            {
                icon: 'mdi-key-change',
                key: 'menu.changePassword',
                link: '/account/change-password'
            },
            {
                icon: 'mdi-key-link',
                key: 'menu.grants',
                link: '/grants'
            },
            {
                icon: 'mdi-logout',
                key: 'menu.logout',
                link: '/passport/logout'
            }
        ]
    }
];
