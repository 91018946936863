import request from '@/utils/request';

export function getPassportViewModel ({ returnUrl }) {
    return request({
        url: '/passport/getPassportViewModel',
        method: 'post',
        data: {
            returnUrl
        }
    });
}

export function loginWithPassword (model) {
    return request({
        url: '/passport/loginWithPassword',
        method: 'post',
        data: model
    });
}

export function loginWithMobile (model) {
    return request({
        url: '/passport/loginWithMobile',
        method: 'post',
        data: model
    });
}

export function loginWithEmail (model) {
    return request({
        url: '/passport/loginWithEmail',
        method: 'post',
        data: model
    });
}

export function sendLoginSmsCaptcha (model) {
    return request({
        url: '/passport/sendLoginSmsCaptcha',
        method: 'post',
        data: model
    });
}

export function sendLoginEmailCaptcha (model) {
    return request({
        url: '/passport/sendLoginEmailCaptcha',
        method: 'post',
        data: model
    });
}

export function sendRegisterSmsCaptcha (model) {
    return request({
        url: '/passport/sendRegisterSmsCaptcha',
        method: 'post',
        data: model
    });
}

export function sendRegisterEmailCaptcha (model) {
    return request({
        url: '/passport/sendRegisterEmailCaptcha',
        method: 'post',
        data: model
    });
}

export function registerPreCheck (model) {
    return request({
        url: '/passport/registerPreCheck',
        method: 'post',
        data: model
    });
}

export function register (model) {
    return request({
        url: '/passport/register',
        method: 'post',
        data: model
    });
}

export function getLogoutViewModel (model) {
    return request({
        url: '/passport/getLogoutViewModel',
        method: 'post',
        data: model
    });
}

export function getLoggedOutViewModel (model) {
    return request({
        url: '/passport/getLoggedOutViewModel',
        method: 'post',
        data: model
    });
}

export function getProvisionViewModel () {
    return request({
        url: '/passport/getProvisionViewModel',
        method: 'post',
        data: {}
    });
}

export function bindCurrentAccount () {
    return request({
        url: '/passport/bindCurrentAccount',
        method: 'post',
        data: {}
    });
}

export function sendResetPasswordSmsCaptcha (model) {
    return request({
        url: '/passport/sendResetPasswordSmsCaptcha',
        method: 'post',
        data: model
    });
}

export function sendResetPasswordEmailCaptcha (model) {
    return request({
        url: '/passport/sendResetPasswordEmailCaptcha',
        method: 'post',
        data: model
    });
}

export function resetPasswordPreCheck (model) {
    return request({
        url: '/passport/resetPasswordPreCheck',
        method: 'post',
        data: model
    });
}

export function resetPassword (model) {
    return request({
        url: '/passport/resetPassword',
        method: 'post',
        data: model
    });
}
