export default [
    {
        path: '/passport',
        redirect: '/passport/login',
        component: () => import('@/views/layouts/passport'),
        children: [
            {
                path: '/passport/login',
                component: () => import('@/views/passport/login')
            },
            {
                path: '/passport/provision',
                component: () => import('@/views/passport/provision')
            },
            {
                path: '/passport/register',
                component: () => import('@/views/passport/register')
            },
            {
                path: '/passport/logout',
                meta: {
                    authorize: true
                },
                component: () => import('@/views/passport/logout')
            },
            {
                path: '/passport/logged-out',
                component: () => import('@/views/passport/loggedOut')
            },
            {
                path: '/passport/forgot-password',
                component: () => import('@/views/passport/forgotPassword')
            }
        ]
    }
];
