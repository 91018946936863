export default {
    models: {
        countries: {
            china: 'China',
            malaysia: 'Malaysia'
        },
        nameFormats: {
            /* eslint-disable */
            unknown: 'N/A',
            surname_GivenName: '{Surname}{Given name}',
            surname_Space_GivenName: '{Surname} {Given name}',
            surname_Dot_GivenName: '{Surname}.{Given name}',
            givenName_Space_Surname: '{Given name} {Surname}'
            /* eslint-enable */
        },
        account: {
            nameIdentifier: 'Account',
            name: 'Name',
            avatar: 'Avatar',
            state: 'State',
            origin: 'Origin',
            givenName: 'Firstname',
            surname: 'Lastname',
            nameFormat: 'Name format',
            email: 'Email',
            mobilePhone: 'Phone',
            sex: 'Gender',
            birthday: 'Birthday',
            registerIp: 'Register IP',
            registerTime: 'Register time',
            loginIp: 'Login IP',
            loginTime: 'Login time',
            loginCount: 'Login times',
            password: 'Password'
        }
    }
};
