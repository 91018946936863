<template>
    <!--比如加入v-application样式，否则颜色不正确 :timeout="timeout"-->

    <v-snackbar
        :color="color"
        :bottom="y === 'bottom'"
        :top="y === 'top'"
        :left="x === 'left'"
        :right="x === 'right'"
        :multi-line="multiLine"
        :vertical="vertical"
        v-model="active"
        class="v-application"
        @click="dismiss"
        role="alert"
    >
        <div v-html="message">
            <slot></slot>
        </div>
        <template v-slot:action="{ attr }">
            <template v-if="showClose">
                <v-btn v-if="closeIcon" :color="color" fab dark x-small v-bind="attr" class="mr-1" @click="close">
                    <v-icon>{{ closeIcon }}</v-icon>
                </v-btn>
                <v-btn v-else color="primary" text v-bind="attr" @click="close">
                    {{ closeText }}
                </v-btn>
            </template>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    props: {
        x: {
            type: String,
            default: ''
        },
        y: {
            type: String,
            default: 'top'
        },
        color: {
            type: String,
            default: 'info'
        },
        icon: {
            type: String,
            default: ''
        },
        iconColor: {
            type: String,
            default: ''
        },
        classes: {
            type: [String, Object, Array],
            default: ''
        },
        message: {
            type: String,
            default: ''
        },
        timeout: {
            type: Number,
            default: 3000
        },
        dismissable: {
            type: Boolean,
            default: true
        },
        multiLine: {
            type: Boolean,
            default: true
        },
        vertical: {
            type: Boolean,
            default: false
        },
        showClose: {
            type: Boolean,
            default: true
        },
        closeText: {
            type: String,
            default: ''
        },
        closeIcon: {
            type: String,
            default: 'mdi-close'
        },
        closeColor: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        active: false
    }),
    mounted () {
        this.$nextTick(() => this.show());
    },
    watch: {
        active: function (isActive, wasActive) {
            this.$emit('statusChange', isActive, wasActive);
        }
    },
    methods: {
        show () {
            this.active = true;
        },
        close () {
            this.active = false;
        },
        dismiss () {
            if (this.dismissable) {
                this.close();
            }
        }
    }
};
</script>

<style>
/* .vts__close {
    margin: 0 -8px 0 24px !important;
    justify-self: flex-end;
} */
/* .vts {
    max-width: none !important;
    width: auto !important;
}
.vts .v-snack__content {
    justify-content: flex-start;
}
.vts__icon {
    margin-right: 12px;
}
.vts__message {
    margin-right: auto;
}
.vts__close {
    margin: 0 -8px 0 24px !important;
    justify-self: flex-end;
}
.vts.v-snack--vertical .vts__icon {
    margin: 0 0 12px !important;
}
.vts.v-snack--vertical .v-snack__content {
    padding-bottom: 16px !important;
}
.vts.v-snack--vertical .vts__message--padded {
    padding: 12px 0 0;
}
.vts.v-snack--vertical .vts__icon + .vts__message {
    padding-top: 0;
}
.vts.v-snack--vertical .vts__close {
    margin: 12px 0 -8px !important;
}
.vts.v-snack--vertical .vts__close--icon {
    margin: 0 !important;
    position: absolute;
    right: 4px;
    top: 4px;
    transform: scale(0.75);
    padding: 4px !important;
} */
</style>
