/**
 * Easy keyboard shortcuts
 * https://github.com/iFgR/vue-shortkey
 */

import VueShortkey from 'vue-shortkey';
export default {
    install (Vue, options) {
        Vue.use(VueShortkey);
    }
};
