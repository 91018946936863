export default {
    models: {
        countries: {
            china: '中国',
            malaysia: '马来西亚'
        },
        nameFormats: {
            /* eslint-disable */
            unknown: 'N/A',
            surname_GivenName: '{姓氏}{名字}',
            surname_Space_GivenName: '{姓氏} {名字}',
            surname_Dot_GivenName: '{姓氏}.{名字}',
            givenName_Space_Surname: '{名字} {姓氏}'
            /* eslint-enable */
        },
        account: {
            nameIdentifier: '登录ID',
            name: '昵称',
            avatar: '头像',
            state: '状态',
            origin: '来源',
            givenName: '名字',
            surname: '姓氏',
            nameFormat: '姓名格式',
            email: '电子邮箱',
            mobilePhone: '手机号码',
            sex: '性别',
            birthday: '生日',
            registerIp: '注册IP',
            registerTime: '注册时间',
            loginIp: '登录IP',
            loginTime: '登录时间',
            loginCount: '登录次数',
            password: '密码'
        }
    }
};
