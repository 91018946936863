export default {
    common: {
        // add: 'Add',
        // cancel: 'Cancel',
        // description: 'Description',
        // delete: 'Delete',
        // title: 'Title',
        save: 'Save',
        submit: 'Submit',
        close: 'Close',
        ok: 'Okay',
        cancel: 'Cancel',
        reset: 'Reset',
        // faq: 'FAQ',
        // contact: 'Contact Us',
        error: 'Some error(s) occurs',
        tos: 'Terms of Service',
        policy: 'Privacy Policy',
        required: 'Required',
        optional: 'Optional'
    }
};
