/**
 * 数据存储，基于lowdb
 * https://github.com/typicode/lowdb
 */
import Lowdb from 'lowdb';
import LocalStorage from 'lowdb/adapters/LocalStorage';

// TODO: 此处是否必要指定要给数据库名
const adapter = new LocalStorage('default');
const lowdb = Lowdb(adapter);

/**
 * 设置存储的默认值
 * 以键值对(Key-Value)的方式保存多个用户的配置信息, Key是用户的usrId (accountId)
 * 如果未出入usrId,那么key的值是空字符串'', 该值被认为用户共享的数据
 */
lowdb
    .defaults({})
    .write();

export default {
    namespaced: true,
    actions: {
        /**
         * 获取数据
         */
        get (context, { path }) {
            return lowdb.get(path).value();
        },

        /**
         * @param {Object} context 上下文
         * @param {Number} usr 用户ID, 如果不传则认为是全局共享数据
         * @param {String} path 数据路径
         * @param {Object} value 存储值
         */
        set (context, { path, value }) {
            lowdb.set(path, value).write();
            return value;
        }
    }
};
