export default [
    {
        path: '/grants',
        component: () => import('@/views/layouts/default'),
        children: [
            {
                path: '/grants',
                meta: {
                    authorize: true
                },
                component: () => import('@/views/grants')
            }
        ]
    }
];
