export default {
    menu: {
        search: '搜索',
        account: '账号',
        profile: '账号资料',
        changePassword: '修改密码',
        grants: '授权',
        logout: '退出'
    }
};
