import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
// 应用路由
Vue.use(VueRouter);

// 路由数据
import passport from './passport';
import account from './account';
import consent from './consent';
import grants from './grants';
import error from './error';

const routes = [
    {
        path: '/',
        redirect: '/account',
        component: () => import('@/views/layouts/default')
    },
    ...passport,
    ...account,
    ...grants,
    ...consent,
    ...error,
    {
        // Terms of services 服务条款
        path: '/tos',
        component: () => import('@/views/tos')
    },
    {
        path: '/policy',
        component: () => import('@/views/policy')
    }
];

const router = new VueRouter({
    routes,
    mode: 'history',
    base: process.env.BASE_URL || '/',
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) return savedPosition;

        return { x: 0, y: 0 };
    }
});

/**
 * 路由拦截，权限验证
 */
router.beforeEach(async (to, from, next) => {
    try {
        // 确保加载必要的存储数据
        await store.dispatch('load');

        var siteProfile = await store.dispatch('app/getSiteProfile');
        document.title = siteProfile.name;

        // 判断是否有权限访问
        var authorize = to.meta && to.meta.authorize;
        if (!authorize) {
            return next();
        }

        // 注意，此处账号未登录还是有信息，accountId===0
        var accountInfo = await store.dispatch('session/getAccountInfo');
        if (accountInfo.accountId) {
            return next();
        } else {
            return next('/passport/login');
        }
    } catch {
        if (to.path === '/error/400') {
            return next();
        } else {
            return next('/error/400');
        }
    }
});

/**
 * After each route update
 */
router.afterEach((to, from) => {
});

export default router;
