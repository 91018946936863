import menu from './menu';

export default {
    menu,

    footer: [{
        text: 'Docs',
        key: 'menu.docs',
        href: 'https://vuetifyjs.com',
        target: '_blank'
    }]
};
