import _ from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

// 遍历modules目录下的文件，并将其合并到modules
const files = require.context('./modules', false, /\.js$/);
const modules = {};
files.keys().forEach(key => {
    modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default;
});

/**
 * Main Vuex Store
 */
export default new Vuex.Store({
    namespaced: true,
    modules,
    actions: {
        /**
         * 加载存储
         */
        async load ({ dispatch }) {
            await dispatch('app/load', null, { root: true });
            await dispatch('session/load', null, { root: true });

            // // TODO: 目前只支持一级，考虑是否要支持多级命名空间处理
            // _.forEach(modules, async (m, k) => {
            //     if (m.namespaced && m.actions && m.actions.load) {
            //         await dispatch(`${k}/load`, null, { root: true });
            //     }
            // });
        }
    }
});
