export default {
    views: {
        layouts: {
            passport: {
                slogan: '欢迎光临！让我们一起创造奇迹。'
            }
        },
        passport: {
            login: {
                welcome: '欢迎登录',
                loginWithPassword: '账号登录',
                loginWithMobile: '手机登录',
                loginWithEmail: '邮箱登录',
                subtitle: '登录到您的账号',
                noaccount: '还没有帐号？',
                create: '在此处创建一个',
                button: '登录',
                orsign: '或使用登录',
                forgot: '忘记密码'
            },
            loginWithPassword: {
                username: '登录ID',
                /*eslint-disable */
                username_mobile: '登录ID / 手机号码',
                username_email: '登录ID / 电子邮箱',
                username_mobile_email: '账号名 / 手机号码 / 电子邮箱',
                /*eslint-enabled */
                password: '密码'
            },
            loginWithMobile: {
                country: '国家',
                mobilePhone: '手机号码',
                captcha: '验证码',
                sendCaptcha: '发送验证码',
                button: '登录'
            },
            loginWithEmail: {
                email: '电子邮箱',
                captcha: '验证码',
                sendCaptcha: '发送验证码',
                button: '登录'
            },
            logout: {
                title: '退出登录',
                alert: '是否退出登录？',
                yes: '是',
                no: '不'
            },
            loggedOut: {
                title: '您已经退出登录。',
                redirect: '<a href="{url}">点击</a>返回到<span>{clientName}</span>'
            },
            register: {
                title: '注册账号',
                subtitle: '开启新旅程',
                country: '国家',
                mobilePhone: '手机号码',
                email: '电子邮箱',
                captcha: '验证码',
                sendCaptcha: '发送验证码',
                nameIdentifier: '登录ID',
                name: '昵称',
                password: '密码',
                next: '下一步',
                prev: '上一步',
                button: '提交',
                hasAccount: '已经有帐号了？',
                login: '登录',
                agree: '签署即表示您同意',
                nameIdentifierHint: '4-20位忽略大小写字符(a-z, 0-9, -, _),并且必须包含字母',
                nameHint: '2-20位忽略大小写字符',
                passwordHint: '6-20位区分大小写字符',
                mobileLoginAccountIs: '您的手机登录账号是：',
                emailLoginAccountIs: '您的邮箱登录账号是：'
            },
            provision: {
                title: '关联账号',
                associateWithCurrent: '关联当前账号({name})',
                associateWithOther: '关联已有账号',
                createNewAccount: '创建新账号'
            },
            forgotPassword: {
                title: '找回密码',
                country: '国家',
                mobilePhone: '手机号码',
                email: '电子邮箱',
                captcha: '验证码',
                sendCaptcha: '发送验证码',
                button: '下一步'
            },
            resetPassword: {
                title: '重设密码',
                newPassword: '新的密码',
                confirmPassword: '确认密码',
                success: '密码已修改，请重新登录'
            }
        },
        account: {
            profile: {
                title: '账号资料',
                nameIdentifierHint: '一年可以修改两次',
                nameHint: '一年可以修改两次',
                editAvatar: '修改形象'
            },
            // 修改手机号码
            editMobilePhoneDialog: {
                title: '修改手机号码',
                country: '国家',
                mobilePhone: '手机号码',
                captcha: '验证码',
                sendCaptcha: '发送验证码'
            },
            // 修改邮箱
            editEmailDialog: {
                title: '修改电子邮箱',
                email: '电子邮箱',
                captcha: '验证码',
                sendCaptcha: '发送验证码'
            },
            // 修改密码
            changePassword: {
                title: '修改密码',
                oldPassword: '旧密码',
                newPassword: '新密码',
                confirmPassword: '确认密码',
                success: '修改密码成功'
            }
        },
        consent: {
            alertHtml: '授权 <b>{clientName}</b>',
            yes: '允许',
            no: '拒绝',
            changeAccount: '使用其他账号',
            invalidConsentRequest: '无效的请求'
        },
        grants: {
            title: '授权',
            revoke: '撤销授权',
            noGrants: '您没有授予访问任何应用程序',
            created: '创建于',
            expires: '过期时间',
            identityGrants: '身份授权',
            apiGrants: 'API授权'
        },
        error: {
            index: {
                title: '出错了！'
            },
            400: {
                title: '哎呀！出状况了',
                content: '我们在努力解决这个问题',
                button: '带我回家'
            },
            404: {
                title: '找不到方向了',
                content: '抱歉!我们没有找到你想访问的页面',
                button: '带我回家'
            }
        }
    }
};
