import Vue from 'vue';
import i18n from '@/i18n';
import vuetify from '@/plugins/vuetify';
import { getAccountInfo } from '@/api';

export default {
    namespaced: true,
    state: {
        currentCulture: null,

        accountInfo: {
            accountId: 0,
            nameIdentifier: null,
            name: null,
            mobilePhone: null,
            email: null,
            avatar: null
        }
    },
    getters: {
        /**
         * 获取当前语言。
         * 调用方法 -> store.getters['session/currentCulture']
         *
         * @param {Object} state vuex state
         */
        currentCulture (state, getters, rootState, rootGetters) {
            return state.currentCulture || rootState.app.suggestCulture;
        }
    },
    actions: {
        async load ({ dispatch, state, rootState }) {
            const path = 'session.currentCulture';
            if (!state.currentCulture) {
                var currentCulture =
                    (await dispatch('db/get', { path }, { root: true })) ||
                    rootState.app.suggestCulture;

                await dispatch('setCurrentCulture', currentCulture);
            }

            // 加载当前登录用户的信息
            await dispatch('getAccountInfo');
        },

        async setCurrentCulture ({ dispatch, state }, value) {
            const path = 'session.currentCulture';
            await dispatch('db/set', { path, value }, { root: true });

            state.currentCulture = value;

            // 设置语言
            i18n.locale = value;
            vuetify.framework.lang.current = value;

            // TODO: 此处需要根据配置来确定是否启用rtl
            // example on how certain languages can be RTL
            if (value === 'ar') {
                vuetify.framework.rtl = true;
            } else {
                vuetify.framework.rtl = false;
            }
        },

        async getAccountInfo ({ dispatch, state }, { force = false } = { }) {
            if (!state.accountInfo.accountId || force) {
                var accountInfo = await getAccountInfo();
                await dispatch('updateAccountInfo', accountInfo);
            }
            return state.accountInfo;
        },

        /**
         * 更新的账号资料
         */
        async updateAccountInfo ({ dispatch, state }, value) {
            if (value) {
                for (var key in value) {
                    Vue.set(state.accountInfo, key, value[key]);
                }
            }
            return state.accountInfo;
        }
    }
};
