import configs from '@/configs';
import vuetify from '@/plugins/vuetify';
import { getSiteProfile, getSupportCultures } from '@/api/app';

// const { product, time, theme, currencies } = configs
const { product, theme } = configs;
const { globalTheme, menuTheme, toolbarTheme, isToolbarDetached, isContentBoxed, isRTL } = theme;
// const { currency, availableCurrencies } = currencies

// state initial values
const state = {
    product,

    // 支持的语言
    supportCultures: null,

    // 最合适的语言
    suggestCulture: null,

    promiseGetSiteProfile: {},

    // time,

    // // currency
    // currency,
    // availableCurrencies,

    // themes and layout configurations
    globalTheme,
    menuTheme,
    toolbarTheme,
    isToolbarDetached,
    isContentBoxed,
    isRTL

    // // App.vue main toast
    // toast: {
    //     show: false,
    //     color: 'black',
    //     message: '',
    //     timeout: 3000
    // }
};

const getters = {
};

const mutations = {
    /**
     * Theme and Layout
     */
    setGlobalTheme: (state, theme) => {
        vuetify.framework.theme.dark = theme === 'dark';
        state.globalTheme = theme;
    },
    setRTL: (state, isRTL) => {
        vuetify.framework.rtl = isRTL;
        state.isRTL = isRTL;
    },
    setContentBoxed: (state, isBoxed) => {
        state.isContentBoxed = isBoxed;
    },
    setMenuTheme: (state, theme) => {
        state.menuTheme = theme;
    },
    setToolbarTheme: (state, theme) => {
        state.toolbarTheme = theme;
    },
    setTimeZone: (state, zone) => {
        state.time.zone = zone;
    },
    setTimeFormat: (state, format) => {
        state.time.format = format;
    },
    setCurrency: (state, currency) => {
        state.currency = currency;
    },
    setToolbarDetached: (state, isDetached) => {
        state.isToolbarDetached = isDetached;
    }
};

const PATH_SUPPORTCULTURES = 'app.supportCultures';
const PATH_SUGGESTCULTURE = 'app.suggestCulture';

const actions = {

    async load ({ dispatch, state }) {
        if (!state.supportCultures || !state.suggestCulture) {
            var [supportCultures, suggestCulture] = await Promise.all([
                dispatch('db/get', { path: PATH_SUPPORTCULTURES }, { root: true }),
                dispatch('db/get', { path: PATH_SUGGESTCULTURE }, { root: true })
            ]);

            if (!supportCultures || !suggestCulture) {
                var r = await getSupportCultures();
                supportCultures = r.supportCultures;
                suggestCulture = r.suggestCulture;
            }

            await dispatch('setSupportCultures', supportCultures);
            await dispatch('setSuggestCulture', suggestCulture);
        }
    },

    /**
     * 设置支持的语言
     */
    async setSupportCultures ({ dispatch, state }, value) {
        await dispatch('db/set', { path: PATH_SUPPORTCULTURES, value }, { root: true });
        vuetify.framework.locales = state.supportCultures;
        state.supportCultures = value;
    },

    /**
     * 设置客户端建议的语言
     */
    async setSuggestCulture ({ dispatch, state }, value) {
        await dispatch('db/set', { path: PATH_SUGGESTCULTURE, value }, { root: true });
        state.suggestCulture = value;
    },

    /**
     * @description 获取当前站点概要信息
     * @param {Object} dispatch vuex dispatch
     * @param {String} culture 指定语言
     */
    getSiteProfile ({ dispatch, state, rootGetters }, { culture } = {}) {
        var sessionCulture = rootGetters['session/currentCulture'];
        culture = culture || sessionCulture || '';

        if (!state.promiseGetSiteProfile[culture]) {
            state.promiseGetSiteProfile[culture] = new Promise(async (resolve) => {
                var path = `app.siteProfile.${culture}`;
                var siteProfile = await dispatch('db/get', { path }, { root: true });
                if (!siteProfile) {
                    siteProfile = await getSiteProfile({ culture });
                    await dispatch('db/set', { path, value: siteProfile }, { root: true });
                }
                resolve(siteProfile);
            });
        }
        return state.promiseGetSiteProfile[culture];
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
