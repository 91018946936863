import VueShortkey from './vue-shortkey';
import BasicMixins from '@/mixins/BasicMixins';

export default {
    install (Vue, options) {
        Vue.use(VueShortkey);

        // 全局混入代码
        Vue.mixin(BasicMixins);
    }
};
