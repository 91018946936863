export default {
    menu: {
        search: 'Search',
        account: 'Account',
        profile: 'Profile',
        changePassword: 'Change password',
        grants: 'Grants',
        logout: 'Logout'
    }
};
