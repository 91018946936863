import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

import en from './en';
import zhHans from './zh-Hans';

// 当前语言
const locale = 'en';
const fallbackLocale = 'en';
const availableLocales = [{
    code: 'en',
    flag: 'us',
    label: 'English',
    messages: en
}, {
    code: 'zh-Hans',
    flag: 'cn',
    label: '简体中文',
    messages: zhHans
}];

const messages = {};

availableLocales.forEach((l) => {
    messages[l.code] = l.messages;
});

export const i18n = new VueI18n({
    locale,
    fallbackLocale,
    messages
});

i18n.locales = availableLocales;

export default i18n;
