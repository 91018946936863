export default [
    {
        path: '/error',
        component: () => import('@/views/error')
    },
    {
        path: '/error/400',
        component: () => import('@/views/error/400.vue')
    },
    {
        path: '*',
        name: 'error',
        component: () => import('@/views/error/404.vue')
    }
];
