import request from './request';
import route from './route';
import validate from './validate';
import error from './error';
import string from './string';

export default {
    request,
    route,
    validate,
    error,
    string
};
