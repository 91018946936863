export default {
    /**
     * 格式化字符串
     * @param {String} format 格式化，例如 'Hello world,{Name}'
     * @param {Object} args 参数, 例如 { Name: 'stcore' }
     */
    format (format, args) {
        var str = format;

        for (var key in args) {
            str = str.replace(new RegExp('\\{' + key + '\\}', 'g'), args[key]);
        }
        return str;
    }
};
